<template>
<div>
    <q-page>
        <div style="display:flex; justify-content:center;">
            <h4>
                IRLs à venir<q-btn v-if="user.is_admin" @click="creation_dialog = true" color="primary" icon="add" style="margin-left:10px;" />
            </h4>
        </div>
        <div class="row justify-center">
            <q-card class="col-8 background" flat>

                <q-dialog v-model="creation_dialog" persistent>
                    <EventCreationForm :edited-event="edited_event" ref="creationForm" @success="onEventCreation"/>
                </q-dialog>

                <!--<q-card-section class="bg-primary text-white flex items-center">
                    <div class="text-h6">Prochains événements</div>
                    <q-space />
                    <q-btn round v-if="user.is_admin" @click="creation_dialog = true" color="secondary" icon="add"/>
                </q-card-section>
                <q-separator />-->

                <q-card-section style="display: flex; justify-content: space-evenly;">
                    <q-card class="eventCard" v-for="event in events">
                        <q-card-section class="bg-dark text-white flex items-center">
                            <strong>{{ event.title }}</strong>
                            <q-space />
                            <q-btn-group>
                                <q-btn icon="edit" v-if="user.is_admin" @click="edit(event)" size="sm" color="white" text-color="black"/>
                                <!-- <q-btn icon="delete" v-if="user.is_admin" @click="remove(event)" size="sm" color="negative"/> -->
                            </q-btn-group>
                        </q-card-section>
                        <q-separator />
                        <q-card-section>
                            <p>
                                Du <strong>{{ event.start_time_formatted }}</strong> au <strong>{{ event.end_time_formatted }}</strong>
                            </p>
                            <p v-if="event.remaining_slots > 0">
                                <q-badge>{{ event.remaining_slots }}</q-badge> places restantes !
                            </p>
                            <div class="bg-negative text-white q-pa-sm" v-else>
                                <q-icon name="warning" style="font-size: 1.5rem;" />
                                Cet évément est complet ! Les inscriptions sont fermées.
                            </div>
                        </q-card-section>
                        <q-separator />
                        <q-card-section class="flex row q-pa-none">
                            <q-btn :to="'/event/' + event.uuid" label="Plus d'informations" color="primary" stretch class="fullSize"/>
                        </q-card-section>

                    </q-card>
                </q-card-section>
            </q-card>
        </div>
        <div style="display:flex; justify-content:center;">
            <h4>
                &Eacute;vènements archivés
            </h4>
        </div>
        <div class="row justify-center">
            <q-card class="col-8 background" flat>
                <q-card-section style="display: flex; justify-content: space-evenly;">
                    <q-card class="eventCard" v-for="event in archived_events">
                        <q-card-section class="bg-dark text-white flex items-center">
                            <strong>{{ event.title }}</strong>
                            <q-space />
                        </q-card-section>
                        <q-separator />
                        <q-card-section>
                            <p>
                                Du <strong>{{ event.start_time_formatted }}</strong> au <strong>{{ event.end_time_formatted }}</strong>
                            </p>
                        </q-card-section>
                        <q-separator />
                        <q-card-section class="flex row q-pa-none">
                            <q-btn :to="'/event/' + event.uuid" label="Plus d'informations" color="primary" stretch class="fullSize"/>
                        </q-card-section>
                    </q-card>
                </q-card-section>
            </q-card>
        </div>
    </q-page>
</div>
</template>

<style scoped>
.eventCard {
    max-width: 250px;
    width: 100%;
}

.fullSize {
    flex: 1;
}
</style>

<script>
import { mapState } from "vuex";
import EventModel from "@/models/event/event.js";
import EventCreationForm from "@/components/EventCreationForm";

export default {
    name: 'EventList',
    computed: mapState(["user"]),
    components: {
        EventCreationForm
    },
    data () {
        return {
            events: [],
            archived_events: [],
            edited_event: undefined,
            creation_dialog: false
        };
    },
    mounted () {
        this.fetch();
    },
    watch: {
        creation_dialog (val) {
            if (!val) {
                this.edited_event = undefined;
            }
        }
    },
    methods: {
        async fetch () {
            let all_events = await EventModel.filter();
            this.events = [];
            this.archived_events = [];

            for (let evt of all_events) {
                if (evt.is_archived) {
                    this.archived_events.push(evt);
                } else {
                    this.events.push(evt);
                }
            }

        },
        onEventCreation () {
            this.fetch();
            this.creation_dialog = false;
        },
        edit (event) {
            this.creation_dialog = true;
            this.edited_event = event;
        },
        remove (event) {

        }
    }
}
</script>
