<template>
    <q-card style="width: 850px; max-width: 80vw;">
        <q-card-section class="row items-center q-pb-none">
            <div class="text-h6">Nouvel événement</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section>
            <q-input v-model="editedEvent.title" label="Titre" />
            <div class="flex">
                <q-input v-model="start_date" label="Date début" type="date" stack-label/>
                <q-input v-model="start_time" label="Heure début" type="time" stack-label class="q-ml-sm"/>
            </div>
            <div class="flex">
                <q-input v-model="end_date" label="Date fin" type="date" stack-label/>
                <q-input v-model="end_time" label="Heure fin" type="time" stack-label class="q-ml-sm"/>
            </div>
            <q-input v-model="editedEvent.address" label="Adresse" />
            <q-input v-model="editedEvent.attendees_limit" label="Nombre maximum de participants" type="number" />
            <q-input v-model="editedEvent.pre_register_code" label="Code de pré-inscription" />
            <q-checkbox v-model="editedEvent.registration_opened" label="Inscriptions Ouvertes" color="teal"/>
            <q-checkbox v-model="editedEvent.is_archived" label="Archivé" color="teal"/>
            <q-editor v-model="editedEvent.description" class="q-mt-sm" min-height="10rem" />
        </q-card-section>
        <q-card-section>
            <div class="text-h6">Paliers tarifaires</div>
            <div class="row items-start q-gutter-md">
                <q-card class="priceCard" v-for="price in prices">
                    <q-card-section>
                        <q-input v-model="price.name" label="Nom" dense />
                        <q-input v-model="price.price" label="Prix Minimum" type="number" dense />
                        <q-editor v-model="price.description" class="q-mt-sm" min-height="10rem" />
                    </q-card-section>
                </q-card>
                <q-card class="flex priceCard flex-center">
                    <q-card-section>
                        <q-btn @click="add_price" icon="add" round size="30px" color="teal" />
                    </q-card-section>
                </q-card>
            </div>
        </q-card-section>
        <q-card-section>
            <div class="text-h6">Suppléments</div>
            <div class="row items-start q-gutter-md">
                <q-card class="priceCard" v-for="extra in extras">
                    <q-card-section>
                        <q-input v-model="extra.name" label="Nom" dense />
                        <q-input v-model="extra.price" label="Prix" type="number" dense />
                        <q-editor v-model="extra.description" class="q-mt-sm" min-height="10rem" />
                    </q-card-section>
                </q-card>
                <q-card class="flex priceCard flex-center">
                    <q-card-section>
                        <q-btn @click="add_extra" icon="add" round size="30px" color="teal" />
                    </q-card-section>
                </q-card>
            </div>
        </q-card-section>
        
        <q-separator />
        <q-card-section class="text-right">
            <q-btn :disabled="is_loading" @click="create_event" color="primary" label="Valider" />
        </q-card-section>
        <q-inner-loading :showing="is_loading">
            <q-spinner-gears size="50px" color="primary" />
        </q-inner-loading>
    </q-card>
</template>

<style scoped>
.priceCard {
    max-width: 250px;
    width: 100%;
    min-height: 350px;
}
</style>

<script>
import { reactive } from "vue";
import moment from "moment";
import Api from "@/utils/api.js";
import EventModel from "@/models/event/event.js";
import EventPrice from "@/models/event/eventprice.js";
import EventExtra from "@/models/event/eventextra.js";

export default {
    name: 'EventCreationForm',
    props: {
        editedEvent: {
            default () { 
                let e = new EventModel({
                    data: {
                        description: "",
                        registration_opened: false
                    }
                });

                return reactive(e);
            }
        }
    },
    data () {
        return {
            is_loading: false,
            start_date: null,
            start_time: null,
            end_date: null,
            end_time: null,
            prices: [],
            extras: []
        };
    },
    async mounted () {
        if (this.editedEvent.phantom) {
            return;
        }
        await this.editedEvent.read(true);
        this.start_date = this.editedEvent.start_time.format("yyyy-MM-DD");
        this.start_time = this.editedEvent.start_time.format("HH:mm");
        this.end_date = this.editedEvent.end_time.format("yyyy-MM-DD");
        this.end_time = this.editedEvent.end_time.format("HH:mm");
        this.prices = [...this.editedEvent.prices];
        this.extras = [...this.editedEvent.extras];
        this.editedEvent.pre_register_code = await Api.post({
            handler: "event.get_preregister_code",
            args: {
                event: this.editedEvent
            }
        });
    },
    methods: {
        add_price () {
            this.prices.push(reactive(new EventPrice({
                data: {
                    description: ""
                }
            })));
        },
        add_extra () {
            this.extras.push(reactive(new EventExtra({
                data: {
                    description: "",
                    price: 0
                }
            })));
        },
        async create_event () {
            this.is_loading = true;
            let start_datetime = moment(`${this.start_date} ${this.start_time}`);
            let end_datetime = moment(`${this.end_date} ${this.end_time}`);
            try {
                this.editedEvent.start_time = start_datetime;
                this.editedEvent.end_time = end_datetime;
                await this.editedEvent.update();

                for (let price of this.prices) {
                    price.event = this.editedEvent;
                    await price.update();
                }
                for (let extra of this.extras) {
                    extra.event = this.editedEvent;
                    await extra.update();
                }
                this.$q.notify({
                    type: "positive",
                    message: `Opération réalisée avec succès!`
                });

                this.$emit("success");
            } catch (e) {
                console.error(e);
            }

            this.is_loading = false;

        }
    }
}
</script>
